export enum CountriesTypes {
    GET_COUNTRIES = "@@countries/GET_COUNTRIES",
    GET_COUNTRIES_SUCCESS = "@@countries/GET_COUNTRIES_SUCCES",
    CLEAR_COUNTRIES = "@@countries/CLEAN_COUNTRIES",
    FAIL_COUNTRIES ="@@countries/FAIL_COUNTRIES",
}
export interface CountriesState {
    data: Array<any>;
    pagination: Object;
    error : Object;
    loading: boolean,
    loaded: boolean,
}


