import { call, put, takeEvery } from "redux-saga/effects"
import { CountriesTypes } from "./actionsTypes"

import { failCountries, getCountriesSuccess } from "./actions";

import {
    getCountries as getCountriesApi
} from "../../helpers/backend_helper";


function* fetchCountries(){
    try{
        const response : Promise<any> = yield call(getCountriesApi);
        yield put(getCountriesSuccess(response))
    }catch (error){
        yield put(failCountries(error));
    }
}

function* countriesSaga() {
    yield takeEvery(CountriesTypes.GET_COUNTRIES, fetchCountries)
}
export default countriesSaga;