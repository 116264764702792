import React, { useState } from 'react';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import { useQuery } from "@tanstack/react-query";
import NoSearchResult from "../../../components/Common/NoSearchResult";
import CardOrder from "./components/CardOrder";
import CreateOrder from "./components/CreateOrder";
import {TrafficOrderService} from "../../../services/traffic_order.service";
import AssignOrder from "./components/AssignOrder";
import AssignIntegra from "./components/AssignIntegra";
import InfoOrder from "./components/InfoOrder";
import UpdateOrder from "./components/UpdateOerder";

const TrafficOrders = () => {
  const [isRight, setIsRight] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isInfoOrder, setIsInfoOrder] = useState(false);
  const [isAssignOrder, setAssignOrder] = useState(false);
  const [isAssignIntegra, setAssignIntegra] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState<any>(null);

  const { data: TrafficOrders,
    isSuccess,
    isLoading: isLoadingTrafficOrders,
    isError: isErrorTrafficOrders
  } = useQuery({
    queryKey: ["keyTrafficOrders"],
    queryFn: TrafficOrderService.getTrafficOrders,
    retry: false,
  });

  const toggleRightCanvas = () => {
    setIsRight((prev) => !prev);
  };

  const toggleUpdateCanvas = (id?: string) => {
    setSelectedOrderId(id);
    setIsUpdate((prev) => !prev);
  }

  const toggleInfoOrder = (id?: string) => {
    setSelectedOrderId(id);
    setIsInfoOrder((prev) => !prev);
  }

  const toggleAssignOrder = (id?: string) => {
    setSelectedOrderId(id);
    setAssignOrder((prev) => !prev);
  };

  const toggleAssignIntegra = (id?: string) => {
    setSelectedOrderId(id);
    setAssignIntegra((prev) => !prev);
  }



  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Dashboard" breadcrumbItem="Traffic Orders" />
        <Row className="align-items-center">
          <Row className="align-items-center">
            <Col md={12}>
              <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                <div>
                  <Button onClick={toggleRightCanvas} className="btn btn-light">
                    <i className="bx bx-plus me-1"></i> Add Order
                  </Button>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                  {isLoadingTrafficOrders ? (
                    <NoSearchResult text="Loading ..." />
                  ) : isErrorTrafficOrders ? (
                    <NoSearchResult text="Error loading data" />
                  ) : (
                    <>
                      {isSuccess ? (
                        <Row>
                          {TrafficOrders.map((val: any, idx: number) => (
                            <CardOrder
                              data={val} key={"card_" + idx}
                              toggleAssignOrder={toggleAssignOrder}
                              toggleAssignIntegra={toggleAssignIntegra}
                              toggleInfoOrder={toggleInfoOrder}
                              toggleUpdate={toggleUpdateCanvas}
                            />
                          ))}
                          {!isSuccess && <NoSearchResult text="No boxes available" />}
                        </Row>
                      ) : (
                        <NoSearchResult text="No boxes available" />
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Row>

        {isRight && <CreateOrder isOpen={isRight} toggleRightCanvas={toggleRightCanvas}/>}
        {isUpdate && <UpdateOrder isOpen={isUpdate} toggleUpdateCanvas={toggleUpdateCanvas} id={selectedOrderId}/>}
        {isInfoOrder && <InfoOrder isOpen={isInfoOrder} toggleInfoOrder={toggleInfoOrder} id={selectedOrderId}/>}
        {isAssignOrder && <AssignOrder isOpen={isAssignOrder} toggleAssignOrder={toggleAssignOrder} id={selectedOrderId} />}
        {isAssignIntegra && <AssignIntegra isOpen={isAssignIntegra} toggleAssignIntegra={toggleAssignIntegra} id={selectedOrderId} />}

      </Container>
    </div>
  );
};

export default TrafficOrders;