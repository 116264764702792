import axios, {AxiosError} from "axios"
import authHeader from "./jwt-token-access/auth-token-header";
import { toast, ToastOptions } from "react-toastify";

//apply base url for axios
//const API_URL = "https://stat-api.detdom.work/api"
const API_URL = process.env.REACT_APP_BASE_API_URL+"/api"

const axiosApi = axios.create({
  baseURL: API_URL,
})

type Theme = 'light' | 'dark' | 'colored';
const storedTheme = localStorage.getItem("layoutTheme");
const theme: Theme = (storedTheme === 'light' || storedTheme === 'dark' || storedTheme === 'colored') ? storedTheme : 'light';

const optionToast: ToastOptions = {
  theme: theme,
};

// const optionToast: ToastOptions = {
//   theme: localStorage.getItem("layoutTheme") || "light" || 'dark',
// };

axiosApi.interceptors.response.use(
  function (response) {
    switch (response?.status) {
      case 200:
        //toast.success("Get Data Success", optionToast);
        break;
      case 204:
        toast.warning("No content", optionToast);
        break;
      default:
          break;
    }
    return response;
  },
    function (error) {

      if (error.response?.status) {
        //console.log(`status ${error.response?.data}`);
        switch (error.response?.status) {
          case 401:
          case 403:
            //location.replace('/logout');
            //break;
          case 400:
          case 404:
          case 405:
            toast.error(error.response?.data?.error?.errorMessage || error.response?.data?.message || error.message, optionToast);
            break;
          case 406:
            toast.error(error.response?.data || error.message, optionToast);
            break;
          case 500:
            toast.error(error.response?.data?.error?.errorMessage || error.response?.data?.message || error.message, optionToast);
            break;
          default:
            break;
        }
      }
      return Promise.reject(AxiosError);
    }
)

export async function get(url : string, config = {}) {
  // @ts-ignore
  axiosApi.defaults.headers.common = authHeader();
  return await axiosApi.get(url, { ...config }).then(response => response.data);

}

export async function post(url : string, data : any, config = {}) {
  // @ts-ignore
  axiosApi.defaults.headers.common = authHeader();

  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url : string, data : any, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url : string, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
