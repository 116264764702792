import React, {useEffect} from "react";
import {Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row} from "reactstrap";
import {useQuery} from "@tanstack/react-query";
import NoSearchResult from "../../../../components/Common/NoSearchResult";
import {TrafficOrderService} from "../../../../services/traffic_order.service";

interface IInfoOrder {
  isOpen: boolean,
  toggleInfoOrder: () => void,
  id: string
}

const pay_methods = [
  {"value": 1, "label": "cpa"},
  {"value": 2, "label": "cpl"},
  {"value": 3, "label": "crg"},
];
const buy_methods = [
  {"value": 1, "label": "Buy"},
  {"value": 2, "label": "Resale"},
];

const InfoOrder = ({isOpen, toggleInfoOrder, id}: IInfoOrder) => {
  /*const dispatch = useDispatch();
  const queryClient = useQueryClient();*/

  /*const { dataCountries, loading } = useSelector((state: any) => ({
    dataCountries: state.Countries.data || [],
    loading: state.Countries.loading
  }));*/

  const {
    data: trafficOrder,
    isLoading: isLoadingTrafficOrder,
    isError: isErrorTrafficOrder
  } = useQuery({
    queryKey: ["keyTrafficOrder", id],
    queryFn: () => TrafficOrderService.getTrafficOrder(+id),
    retry: false,
  });

  /*const { data: brokerData, isLoading, isError } = useQuery({
    queryKey: ["keyBrokerList"], queryFn: BrokerService.getBrokerList, staleTime: 60000 * 10
  });

  const { data: buyerData, isLoading: isLoadingBuyer, isError: isErrorBuyer } = useQuery({
    queryKey: ["keyBuyerTeamList"], queryFn: UserService.getBuyerTeamLead
  });

  const {mutate: createTrafficOrder, data: resultData, isError: isErrorCreateTrafficOrder, isPending, isSuccess: isSuccessCreateTrafficOrder, error, status} = useMutation({
    mutationFn: TrafficOrderService.createTrafficOrder,
    mutationKey: ['keyCrateTrafficOrder']
  });*/

  /*useEffect(() => {
    dispatch(getCountries());
    return () => {
      dispatch(clearCountries());
    };
  }, [dispatch]);*/

  useEffect(() => {
    if(isErrorTrafficOrder) {
      toggleInfoOrder()
    }
  }, [isErrorTrafficOrder]);


  return (
    <Offcanvas isOpen={isOpen && !isErrorTrafficOrder} direction="end" toggle={toggleInfoOrder}>
      <OffcanvasHeader toggle={toggleInfoOrder}>
        Info Traffic Order {trafficOrder?.id}
      </OffcanvasHeader>

      {!isLoadingTrafficOrder ? (
        <OffcanvasBody>

          <div className="mb-2">
            <label>Date: {trafficOrder?.from_date} - {trafficOrder?.to_date}</label>
          </div>

          <div className="mb-2">
            <label>Broker: {trafficOrder?.broker?.name}</label>
            <div>
              <span className={`badge ${trafficOrder.broker_id ? 'bg-success' : 'bg-danger'}`}>
                <i
                  className={`bx ${trafficOrder.broker_id ? 'bx-check-double' : 'bx-block'} font-size-16 align-middle me-2`}/>
                {trafficOrder.broker_id ? 'Integrated' : 'Not Integrated'}
              </span>
            </div>
          </div>

          <div className="mb-2">
            <label>Buy method: {trafficOrder?.buy_method_label}</label>
          </div>

          {trafficOrder?.buy_method_id === 2 && (
            <div className="mb-2">
              <label>Resale: {trafficOrder?.resale}</label>
            </div>
          )}

          {trafficOrder?.buy_method_id === 1 && (
            <div className="mb-2">
              <label>Teams: {trafficOrder.users
                .filter((i: any) => i.team_id > 0 && !i.is_responsible && i.role)
                .map((i: any) => i.name)
                .join(", ")}
              </label>
            </div>
          )}

          <div className="mb-2">
            <label>Responsible: {trafficOrder.users
              .filter((i: any) => i.team_id > 0 && i.is_responsible)
              .map((i: any) => i.name)
              .join(", ")}</label>
          </div>

          <div className="mb-2">
            <label>Order GEO: {trafficOrder.countries
              .map((i: any) => i.title+" | "+i.iso)
              .join(", ")}</label>
          </div>

          <div className="mb-2">
            <label>Funnel name: {trafficOrder?.funnel_name}</label>
          </div>

          <div className="mb-2">
            <label>Pay method: {trafficOrder?.buy_method_label}</label>
          </div>

          <Row>
            <div className={`mb-2 ${trafficOrder?.pay_method_id === 3 ? "col-8" : ""}`}>
              <label>Payout: {trafficOrder?.payout}</label>
            </div>
            {trafficOrder?.pay_method_id === 3 && (
              <div className="mb-2 col-4">
                <label>CR {trafficOrder?.cr} %</label>
              </div>
            )}
          </Row>

          <div className="mb-2">
            <label>Daily Cap: {trafficOrder?.daily_cap}</label>
          </div>

          <Row>
            <div className="mb-2">
              <label>Call center time: {trafficOrder?.from_time} - {trafficOrder?.to_time}</label>
            </div>
          </Row>

        </OffcanvasBody>
      ) : (
        <NoSearchResult text="Loading ..."/>
      )}
    </Offcanvas>
  );
};

export default InfoOrder;