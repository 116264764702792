import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard";

//Authentication pages
import Login from "src/pages/Authentication/Login";
import Logout from "src/pages/Authentication/Logout";
import UserProfile from "src/pages/Authentication/user-profile";
import Brokers from "../pages/Brokers";
import Teams from "../pages/Teams";
import Users from "../pages/Users";
import Checkin from "src/pages/Checkin";
import Spends from "src/pages/Spends";
import Permissions from "../pages/Permissions";
import Roles from "src/pages/Roles";
import NoAccess from "src/pages/NoAccess";
import StatisticBuyer from "../pages/StatisticBuyer";
import PriceDay from "src/pages/Price_day";
import Orders from "src/pages/Orders";
import Tracker from "src/pages/Tracker";
import JiraStatistic from "../pages/Jira";
import LeadDashboard from "src/pages/LeadBoard";

import Checker from "../pages/Checker";
import {Uni} from "../pages/Uni";
import KioDomainChanger from "../pages/KioDomainChanger";
import CountryBoxes from "../pages/TrafficManagement/CountryBoxes"
import AddBox from "../pages/TrafficManagement/CountryBoxes/AddBox";
import EditBox from "../pages/TrafficManagement/CountryBoxes/EditBox";
import TrafficOrders from "../pages/TrafficManagement/TrafficOrders";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  { path: "/profile", component: <UserProfile /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/lead-dashboard", component: <LeadDashboard />},
  { path: "/brokers", component: <Brokers /> },
  { path: "/spends", component: <Spends />},
  { path: "/teams", component: <Teams /> },
  { path: "/users", component: <Users /> },
  { path: "/check-in", component: <Checkin /> },
  { path: "/permissions", component: <Permissions /> },
  { path: "/roles", component: <Roles /> },
  { path: "/statistic-buyer", component: <StatisticBuyer /> },
  { path: "/price-day", component: <PriceDay />},
  { path: "/orders", component: <Orders />},
  { path: "/tracker-capa", component: <Tracker />},
  { path: "/jira-statistic", component: <JiraStatistic />},
  { path: "/kio-domain-changer", component: <KioDomainChanger />},
  { path: "/dns-checker", component: <Checker />},
  { path: "/uni", component: <Uni />},
  { path: "/country-boxes", component: <CountryBoxes />},
  { path: "/country-boxes/add", component: <AddBox /> },
  { path: "/country-boxes/edit/:id", component: <EditBox /> },
  { path: "/traffic-orders", component: <TrafficOrders /> },
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },


];

const authRoutes: Array<RouteProps> = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  {path: "/no-access", component: <NoAccess/>},

];

export { userRoutes, authRoutes };
