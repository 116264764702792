import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button, Input, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { clearCountries, getCountries } from "../../../store/countries/actions";
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CountryBoxService } from "../../../services/country_box.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

interface Country {
    id: number;
    title: string;
    iso: string;
    phone_code: string;
    originalIndex?: number;
}

const validationSchema = Yup.object({
    selectedCountries: Yup.array().min(1, "Вы должны выбрать хотя бы одну страну").required("Выбор стран обязателен"),
    name: Yup.string().required("Название коробки обязательно")
});

const EditBox = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dataCountries = useSelector((state: any) => state.Countries.data || []);
    const [availableCountries, setAvailableCountries] = useState<Country[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const { data: boxData, isLoading: isBoxLoading } = useQuery({
        queryKey: ["keyCountryBoxes", id],
        queryFn: () => CountryBoxService.getCountryBoxById(id),
        enabled: !!id,
    });

    const { mutate: updateBox, isSuccess, isError, isPending } = useMutation({
        mutationFn: (data: any) => CountryBoxService.updateBox(id, data),
    });

    useEffect(() => {
        dispatch(getCountries());
        return () => {
            dispatch(clearCountries());
        };
    }, [dispatch]);

    useEffect(() => {
        setAvailableCountries(dataCountries);
    }, [dataCountries]);

    useEffect(() => {
        if (isSuccess) {
            navigate('/country-boxes');
        }
    }, [isSuccess, navigate]);

    const insertCountryAtOriginalIndex = (country: Country) => {
        const indexToInsert = country.originalIndex ?? availableCountries.length;
        const updatedCountries = [...availableCountries];
        updatedCountries.splice(indexToInsert, 0, country);
        setAvailableCountries(updatedCountries);
    };

    if (isBoxLoading) {
        return <div>Loading...</div>;
    }

    return (
      <div className="page-content">
          <Container fluid>
              <Breadcrumbs title="Dashboard" breadcrumbItem="Edit Country Box" />

              <Formik
                initialValues={{ name: boxData?.name || "", selectedCountries: boxData?.countries || [] }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    const data = {
                        name: values.name,
                        country_ids: values.selectedCountries.map((i: any) => i.id)
                    };
                    updateBox(data);
                }}
                enableReinitialize={true}
              >
                  {({ values, handleSubmit, setFieldValue, isValid, dirty }) => {
                      const filteredCountries = availableCountries.filter(
                        (country) =>
                          !values.selectedCountries.some(
                            (selectedCountry: Country) => selectedCountry.id === country.id
                          ) &&
                          country.title.toLowerCase().includes(searchTerm.toLowerCase())
                      );

                      return (
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={12} className="mb-4">
                                    <Input
                                      type="text"
                                      placeholder="Название коробки"
                                      value={values.name}
                                      onChange={(e) => setFieldValue("name", e.target.value)}
                                      className="mb-2"
                                    />
                                </Col>
                                <Col md={4}>
                                    <h4>Все страны</h4>
                                    <Input
                                      type="text"
                                      placeholder="Поиск по странам"
                                      value={searchTerm}
                                      onChange={(e) => setSearchTerm(e.target.value)}
                                      className="mb-2"
                                    />

                                    <FieldArray
                                      name="selectedCountries"
                                      render={arrayHelpers => (
                                        <div style={{ height: '500px', overflowY: 'auto', borderRight: '1px solid #ddd', padding: '10px' }}>
                                            {filteredCountries.length > 0 ? (
                                              filteredCountries.map((country, index) => (
                                                <div key={index}>
                                                    <Label
                                                      style={{ cursor: 'pointer' }}
                                                      onClick={() => {
                                                          const countryWithIndex = { ...country, originalIndex: availableCountries.indexOf(country) };
                                                          arrayHelpers.push(countryWithIndex);
                                                          setAvailableCountries(availableCountries.filter(c => c.id !== country.id));
                                                      }}
                                                    >
                                                        {country.title}
                                                    </Label>
                                                </div>
                                              ))
                                            ) : (
                                              <p>Нет доступных стран</p>
                                            )}
                                        </div>
                                      )}
                                    />
                                </Col>

                                <Col md={8}>
                                    <h4>Выбранные страны</h4>
                                    <FieldArray
                                      name="selectedCountries"
                                      render={arrayHelpers => (
                                        <div>
                                            {values.selectedCountries.length > 0 ? (
                                              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                                  {values.selectedCountries.map((country: Country, index: number) => (
                                                    <span
                                                      key={country.id}
                                                      className="bg-primary badge bg-secondary font-size-15"
                                                      style={{ cursor: 'pointer' }}
                                                      onClick={() => {
                                                          arrayHelpers.remove(index);
                                                          insertCountryAtOriginalIndex(country);
                                                      }}
                                                    >
                                                      {country.title} <span style={{ marginLeft: '10px', color: 'white', cursor: 'pointer' }}>×</span>
                                                    </span>
                                                  ))}
                                              </div>
                                            ) : (
                                              <p>Нет выбранных стран</p>
                                            )}
                                        </div>
                                      )}
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Col>
                                    <div className="text-end">
                                        <Button type="submit" color="primary" className="mt-3" disabled={!isValid || !dirty}>
                                            Save
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                      );
                  }}
              </Formik>
          </Container>
      </div>
    );
};

export default EditBox;