import PropTypes from "prop-types";
import React, {useState} from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Progress,
} from "reactstrap";
import {useQueryClient} from "@tanstack/react-query";

const ORDER_STATUSES = [
  { value: 1, label: "Active", colorClass: "bg-success", iconClass: "bx-check-double" },
  { value: 2, label: "Pause", colorClass: "bg-warning", iconClass: "bx-error" },
  { value: 3, label: "Stop", colorClass: "bg-danger", iconClass: "bx-block" },
];

const CardOrder = (props: any) => {

  const {
    data,
    toggleAssignOrder,
    toggleAssignIntegra,
    toggleInfoOrder,
    toggleUpdate
  } = props;

  const status = ORDER_STATUSES.find((status) => status.value === data?.status);

  return (
    <React.Fragment>
      <Col xl="3" md="3" sm="4">
        <Card className="text-center">
          <CardBody>

            {/*<div className="btn-group justify-content-between mb-2" style={{display: "flex"}} role="group">
              <div className={"text-start"}>
                {
                  //hasPermission("delete-country-box") &&
                  <Link className="text-danger" to="#">
                    <i
                      className="mdi mdi-delete font-size-12"
                      id="deletetooltip"
                      onClick={onDelete}
                    />
                  </Link>
                }
              </div>
              <div className={"text-end"}>
                {
                  //hasPermission("update-country-box") &&
                  <Link className="text-success" to={`/country-boxes/edit/${data.id}`}>
                    <i className="mdi mdi-pencil font-size-12"/>
                  </Link>
                }
              </div>
            </div>*/}

            <h5 className="font-size-16 mb-1">
              <Link to={`/country-boxes/edit/${data.id}`} className="text-body">
                {data?.broker?.name} {data?.funnel_name} {data?.pay_method_label}
              </Link>
            </h5>

            <>
              <div style={{ position: 'relative', width: '100%' }}>
                <Progress striped max={data?.daily_cap || 0} value={10} className="my-1" color={"success"} style={{ height: '20px', backgroundColor: '#B9BBB6' }} >
                <span style={{
                  position: 'absolute',
                  width: '100%',
                  textAlign: 'center',
                  color: 'white',
                  lineHeight: '20px'
                }}>
                  {10}/{data?.daily_cap || 0}
                </span>

                </Progress>
              </div>
            </>

            <div className={"font-size-12"}>
              {data.countries.map((i: any) => {
                return (<span key={i.id}>{i.iso} </span>)
              })}
            </div>

            <div>
              <span className={`badge ${status?.colorClass || 'bg-secondary'}`}>
                <i className={`bx ${status?.iconClass || 'bx-info-circle'} font-size-16 align-middle me-2`}/>
                {status?.label || 'Unknown'}
              </span>
            </div>

            <div>
              <span className={`badge ${data.broker_id ? 'bg-success' : 'bg-danger'}`}>
                <i className={`bx ${data.broker_id ? 'bx-check-double' : 'bx-block'} font-size-16 align-middle me-2`}/>
                {data.broker_id ? 'Integrated' : 'Not Integrated'}
              </span>
            </div>

            <div className={"mt-2"}>
              {data.responsible_users && data?.responsible_users.map((i: any, idx: number) => {
                return <span className="rounded-pill bg-dark-subtle text-dark badge bg-secondary " key={idx}>{i.name}</span>
              })}
            </div>
          </CardBody>

          <div className="btn-group" role="group">
            <button type="button" className="btn btn-outline-light text-truncate" title="Assign" onClick={() => toggleAssignOrder(data.id)}>
              <i className="mdi mdi-plus-circle font-size-14" />
            </button>
            <button type="button" onClick={() => toggleInfoOrder(data.id)} className="btn btn-outline-light text-truncate" title="Info">
              <i className="mdi mdi-information font-size-14" />
            </button>
            <button type="button" onClick={() => toggleUpdate(data.id)} className="btn btn-outline-light text-truncate" title="Edit">
              <i className="mdi mdi-pencil font-size-14" />
            </button>
            <button type="button" onClick={() => toggleAssignIntegra(data.id)} className="btn btn-outline-light text-truncate" title="Integrated">
              <i className="mdi mdi-book-cog font-size-14" />
            </button>
          </div>

        </Card>
      </Col>
    </React.Fragment>
  );
};

CardOrder.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  onDelete: PropTypes.func,
  toggleAssignOrder: PropTypes.func,
  toggleAssignIntegra: PropTypes.func,
  toggleInfoOrder: PropTypes.func,
  toggleUpdate: PropTypes.func
};

export default CardOrder;
