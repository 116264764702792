import React, {useEffect} from "react";
import * as Yup from "yup";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import { BrokerService } from "../../../../services/broker.service";
import { Form, ModalBody, ModalHeader, Modal, Row, Col } from "reactstrap";
import Select from "react-select";
import { useFormik } from "formik";
import { TrafficOrderService } from "../../../../services/traffic_order.service";

interface IAssignIntegra {
  isOpen: boolean;
  toggleAssignIntegra: () => void;
  id: string;
}

const validationSchema = Yup.object({
  broker_id: Yup.number().required("At least one broker must be selected"),
});

interface AssignBroker {
  broker_id: number;
}

const AssignIntegra: React.FC<IAssignIntegra> = ({ isOpen, toggleAssignIntegra, id }) => {
  const queryClient = useQueryClient();

  const {
    data: brokerData,
    isLoading: isLoadingBrokers
  } = useQuery({
    queryKey: ["keyBrokerList"],
    queryFn: BrokerService.getBrokerList,
  });

  const {
    data: brokerTrafficOrder,
    isLoading: isLoadingBrokerTrafficOrder,
    isError: isErrorBrokerTrafficOrder
  } = useQuery({
    queryKey: ["keyBrokerTrafficOrder", id],
    queryFn: () => TrafficOrderService.getBrokerTrafficOrder(+id),
    retry: false,
  });

  const {
    mutate: updateBrokerTrafficOrder,
    isPending:isPendingUpdateBrokerTrafficOrder,
    isSuccess: isSuccessUpdateBrokerTrafficOrder
  } = useMutation({
    mutationFn: (data: AssignBroker) => TrafficOrderService.updateBrokerTrafficOrder(data, +id),
    mutationKey: ["keyUpdateBrokerTrafficOrder", id],
  });

  const formik = useFormik({
    initialValues: {
      broker_id: brokerTrafficOrder?.broker_id || null,
    },
    onSubmit: (values) => {
      updateBrokerTrafficOrder(values)
    },
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const { submitForm, setFieldValue, values, touched, errors, isValid } = formik;

  useEffect(() => {
    if(isErrorBrokerTrafficOrder || isSuccessUpdateBrokerTrafficOrder){
      toggleAssignIntegra();
      queryClient.invalidateQueries({ queryKey: ['keyTrafficOrders'] });
    }
  }, [isErrorBrokerTrafficOrder, isSuccessUpdateBrokerTrafficOrder]);

  return (
    <Modal isOpen={isOpen} toggle={toggleAssignIntegra}>
      <ModalHeader toggle={toggleAssignIntegra}>Assign Integra Order {id}</ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col xs={12}>
              <div className="mb-4">
                <label>Select broker</label>
                <Select
                  name="broker_id"
                  options={brokerData?.map((broker: any) => ({
                    value: broker.value, label: broker.label
                  })) || []}
                  placeholder="Select broker..."
                  isClearable
                  isLoading={isLoadingBrokers}
                  onChange={(option: any) => setFieldValue("broker_id", option ? option.value : null)}
                  value={brokerData?.find((broker: any) => broker.value === values.broker_id) || null}
                />
                {/*{touched.broker_id && errors.broker_id && (
                  <div className="text-danger">{errors?.broker_id}</div>
                )}*/}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-end">
              <button
                type="button"
                className="btn btn-success save-user"
                onClick={submitForm}
                disabled={!isValid}
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default AssignIntegra;