import React, { useState } from 'react';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { CountryBoxService } from "../../../services/country_box.service";
import NoSearchResult from "../../../components/Common/NoSearchResult";
import CardBox from "./CardBox";
import DeleteModal from "../../../components/Common/DeleteModal";

const CountryBoxes = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedBoxId, setSelectedBoxId] = useState<number | null>(null); // State to hold the selected box ID for deletion
  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["keyCountryBoxes"],
    queryFn: CountryBoxService.getCountryBoxes,
    refetchInterval: 60000 * 2,
    refetchIntervalInBackground: true,
    enabled: true,
    refetchOnWindowFocus: true
  });

  const { mutate: deleteBox, isPending: isDeleting , } = useMutation({
    mutationFn: (id: number) => CountryBoxService.destroyBox(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['keyCountryBoxes']});
      setDeleteModal(false);
      setSelectedBoxId(null);
    },
    onError: () => {
      setDeleteModal(false);
      setSelectedBoxId(null);
    }
  });

  const openDeleteModal = (id: number) => {
    setSelectedBoxId(id);
    setDeleteModal(true);
  };

  const handleDeleteBox = () => {
    if (selectedBoxId !== null) {
      deleteBox(selectedBoxId);
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Dashboard" breadcrumbItem="Country Boxes" />
        <Row className="align-items-center">
          <Row className="align-items-center">
            <Col md={12}>
              <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                <div>
                  <Link to="/country-boxes/add" className="btn btn-light">
                    <i className="bx bx-plus me-1"></i> Add Box
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                  {isLoading ? (
                    <NoSearchResult text="Loading ..." />
                  ) : isError ? (
                    <NoSearchResult text="Error loading data" />
                  ) : (
                    <>
                      {data.length > 0 ? (
                        <Row>
                          {data.map((val: any, idx: number) => (
                            <CardBox
                              data={val}
                              id={"box_" + idx}
                              key={"box_" + idx}
                              onDelete={() => openDeleteModal(val.id)}
                            />
                          ))}
                          {!data.length && <NoSearchResult text="No boxes available" />}
                        </Row>
                      ) : (
                        <NoSearchResult text="No boxes available" />
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {deleteModal && (
            <DeleteModal
              show={deleteModal}
              onDeleteClick={handleDeleteBox}
              onCloseClick={() => setDeleteModal(false)}
            />
          )}
        </Row>
      </Container>
    </div>
  );
};

export default CountryBoxes;