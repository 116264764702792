import { CountriesTypes } from "./actionsTypes";

export const getCountries = () =>({
    type: CountriesTypes.GET_COUNTRIES,
})
export const getCountriesSuccess = (data : any) =>({
    type: CountriesTypes.GET_COUNTRIES_SUCCESS,
    payload: data,
})

export const clearCountries = () => ({
    type: CountriesTypes.CLEAR_COUNTRIES,
})

export const failCountries = (error : any) => ({
    type: CountriesTypes.FAIL_COUNTRIES,
    payload: error,
})
