import { del, get, post, put } from "../helpers/api_helper"

export const CountryBoxService = {

    getCountryBoxes: async () => {
        return get('/country-boxes');
    },

    createBox: async (data: any) => {
        return post('/country-boxes', data)
    },

    getCountryBoxById: async (id: any) => {
      return get(`/country-boxes/${id}`)
    },

    updateBox: async (id: any, data: any) => {
      return put(`/country-boxes/${id}`, data)
    },

    destroyBox: async (id: any) => {
        return del(`/country-boxes/${id}`)
    }

}