import React, {createContext, PropsWithChildren, useEffect, useMemo, useState} from "react";
import Echo from "laravel-echo";
import Pusher from 'pusher-js';
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
// @ts-ignore
import notificationSoundFile from '../assets/audio/notification.mp3';
import {useQueryClient} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";

// Pusher.logToConsole = true

export const MyEcho = new Echo({
    broadcaster: 'pusher',
    Pusher,
    key: 'app-key', // set the key defined in your .env new-lawyer-key
    wsHost: 'soketi.projektsolutions.tech', // the host defined in your .env
    wssHost: 'soketi.projektsolutions.tech', // the host defined in your .env
    wsPort: 443, // or the port defined in your .env
    wssPort: 443, // or the port defined in your .env
    forceTLS: true,
    encrypted: true,
    cluster: 'mt1',
    enabledTransports: ['ws', 'wss'],
})

/*export const MyEcho = new Echo({
    broadcaster: 'pusher',
    Pusher,
    key: 'app-key', // set the key defined in your .env new-lawyer-key
    wsHost: 'localhost', // the host defined in your .env
    wsPort: 6001, // or the port defined in your .env
    forceTLS: false,
    encrypted: true,
    cluster: 'mt1',
    enabledTransports: ['ws', 'wss'],
})*/

export const BroadcastContext = createContext<any>(null);

export const BroadcastProvider = ({children}: PropsWithChildren) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [instance, setInstance] = useState<any>(null);
    const [currentPrivateChannel, setCurrentPrivateChannel] = useState<any>(null);
    const [systemChannel, setSystemChannel] = useState<any>(null);
    const [notifications, setNotifications] = useState<any[]>([]);

    const loginUserId = useSelector((state: any) => state.login.data.user_id || null);
    const authUser = localStorage.getItem('authUser');
    const parsedAuthUser = authUser ? JSON.parse(authUser) : {};

    const user_id  = parsedAuthUser?.user_id || loginUserId;

    const broadcast = {
        instance,
        currentPrivateChannel,
        systemChannel,
        notifications,
    };

    useEffect(() => {
        if (user_id) {
            const notificationSound = new Audio(notificationSoundFile);

            MyEcho.channel(`App.Models.User.${user_id}`)
              .subscribed(() => {
                  console.log(`Подписка на канал: App.Models.User.${user_id}`);
              })
              .listen('.lead.event', (data: any) => {

                  if (!data || !data.data) {
                      console.warn('Пустые данные или поле data не существует:', data);
                      return;
                  }

                  const {data: loadData} = data
                  if (loadData) {
                      notificationSound.play().catch((error) => console.error('Ошибка при воспроизведении звука:', error));
                      toast.info(() => (
                        <div>
                            <strong>User Information: </strong>
                            <ul>
                                {Object.entries(loadData).map(([key, value]: [string, any], index: number) => (
                                  <li key={index}> {key}: {value}  </li>
                                ))}
                            </ul>
                        </div>
                      ), {
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          autoClose: false,
                      });
                  }
              })
              .listen('.traffic.order.event', (data: any) => {
                  const { data: result } = data
                    toast.info(() => (
                      <div onClick={() => navigate('/traffic-orders')}>
                          <strong>Traffic Order Information: </strong>
                          <div> {result.message}</div>
                      </div>
                    ), {
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        autoClose: false,
                    });
                  queryClient.invalidateQueries({ queryKey: [result?.key] });
              });

            return () => {
                MyEcho.leaveChannel(`App.Models.User.${user_id}`);
            };
        }
    }, [user_id]);

    return (
        <BroadcastContext.Provider value={broadcast}>
            {children}
        </BroadcastContext.Provider>
    );
}
