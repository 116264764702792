import { CountriesTypes, CountriesState } from './actionsTypes';
export const INIT_STATE: CountriesState = {
    data: [],
    pagination: {},
    error: {},
    loading: false,
    loaded: false,
}

const countries = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case CountriesTypes.GET_COUNTRIES:
            return {
                ...state,
                loading: true
            }
        case CountriesTypes.GET_COUNTRIES_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                pagination: action.payload.pagination,
                loaded: true,
                loading: false
            }

        case CountriesTypes.CLEAR_COUNTRIES: return INIT_STATE;
        case CountriesTypes.FAIL_COUNTRIES:
            return {
                ...state,
                error: action.payload,
                loaded: false,
                loading: false
            }

        default:
            return state
    }
}

export default countries