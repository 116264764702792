import PropTypes from "prop-types";
import React, {useState} from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle, Progress,
  UncontrolledDropdown,
  Tooltip, UncontrolledTooltip, Row
} from "reactstrap";

const CardCap = (props: any) => {
  const { data, id, onDelete } = props;

  return (
    <React.Fragment>
      <Col xl="2" sm="3">
        <Card className="text-center">
          <CardBody>

            <div className="btn-group justify-content-between mb-2" style={{display: "flex"}} role="group">
              <div className={"text-start"}>
                {
                  //hasPermission("delete-country-box") &&
                  <Link className="text-danger" to="#">
                    <i
                      className="mdi mdi-delete font-size-12"
                      id="deletetooltip"
                      onClick={onDelete}
                    />
                  </Link>
                }
              </div>
              <div className={"text-end"}>
                {
                  //hasPermission("update-country-box") &&
                  <Link className="text-success" to={`/country-boxes/edit/${data.id}`}>
                    <i className="mdi mdi-pencil font-size-12" />
                  </Link>
                }
              </div>
            </div>

            <h5 className="font-size-16 mb-1">
              <Link to={`/country-boxes/edit/${data.id}`} className="text-body">
                {data.name}
              </Link>
            </h5>

            <div className={"font-size-12"}>
              {data.countries.map((i: any) => {
                return ( <span key={i.id}>{i.iso} </span> )
              })}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

CardCap.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  onDelete: PropTypes.func,
};

export default CardCap;
